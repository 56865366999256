.MainServicesArea {
	padding: 120px 0px 100px;
	display: flex;
	justify-content: center;

	.aboutImageWrap {
		margin: 0px 20px 0px 20px;
		position: relative;
		z-index: 1;
		@media (max-width: 768px) {
			margin-bottom: 40px;
			margin-right: 0;
		}
		&::before {
			content: "";
			background-color: #c0b596;
			width: 100%;
			height: 100%;
			position: absolute;
			left: -35px;
			top: -20px;
			z-index: -1;
		}
		i {
			border: 1px solid #fff;
			height: 55px;
			width: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			color: #fff;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background: #d4c291;
			}
		}
	}
	.aboutContent {
		.headerContainer {
			width: 400px;
			margin: auto;
		}
		span {
			font-size: 16px;
			color: #ada282;
			display: block;
		}
		h2 {
			margin-bottom: 20px;
			&::before {
				content: "";
				position: absolute;
				left: 120px;
				top: 19px;
				width: 60px;
				height: 1px;
				background: #c0b596;
			}
		}

		.separatorForm {
			display: none;
			width: 60px;
			height: 2px;
			background: #c0b596;
			margin: -15px 0px 10px 0px;
		}
		p {
			margin-bottom: 30px;
			font-size: 15px;
			@media (max-width: 1200px) {
				font-size: 14px;
				margin-bottom: 20px;
			}
		}
		.signature {
			margin-top: 35px;
		}

		.inputContainer {
			display: flex;
			justify-content: center;
		}
	}
	&.aboutAreaStyleTwo {
		padding: 100px 0px;
		.aboutImageWrap {
			margin: 0px 10px 10px 0px;
			&::before {
				left: auto;
				right: -10px;
				top: auto;
				bottom: -10px;
			}
		}
		.aboutContent {
			@media (max-width: 800px) {
				margin-bottom: 40px;
			}

			@media (max-width: 1280px) {
				h2 {
					&::before {
						left: -25px;
					}
				}
			}

			@media (max-width: 959px) {
				h2 {
					&::before {
						left: 100px;
					}
				}
			}

			@media (max-width: 599px) {
				h2 {
					&::before {
						display: none;
					}
				}
				.headerContainer {
					width: 300px;
					margin: auto;
				}
			}
			@media (max-width: 599px) {
				.separatorForm {
					display: block;
					width: 60px;
					height: 2px;
					background: #c0b596;
					margin: -15px 0px 10px 0px;
				}
			}
		}
		.inputWidth {
			//     width: 400px;
		}

		.btnContainer {
			width: 400px;
			margin: auto;
		}

		@media (max-width: 599px) {
			.btnContainer {
				width: 300px;
				margin: auto;
			}
		}
		.btnLarge {
			width: 140px;
			outline: none !important;
		}

		.formInput {
			width: 400px;
			label,
			input,
			select,
			.MuiSelect-select {
				padding-left: 14px;
			}

			background: rgba(192, 181, 150, 0.2);
			height: 50px;
			color: #bbb5a5;
			border-radius: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
			border: 1px solid rgba(192, 181, 150, 0.5);
			&:focus {
				-webkit-box-shadow: none;
				box-shadow: none;
				border-color: #c0b596;
			}
			&::-webkit-input-placeholder {
				color: #bbb5a5;
				font-size: 14px;
			}
		}
		@media (max-width: 599px) {
			.formInput {
				width: 302px;
			}
		}
		select {
			padding-left: 15px;
			color: #bbb5a5;
			position: relative;
			-webkit-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance: none;
			-moz-appearance: none;
		}
		.MuiInput-underline:before {
			border-bottom-color: #c4c4c4;
		}
		.MuiInput-underline:hover:before {
			border-bottom-color: rgba(0, 0, 0, 0.6);
		}
		.MuiInput-underline:after {
			border-bottom-color: rgba(146, 140, 123, 0.6) !important;
		}
	}
}
