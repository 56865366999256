.MuiDialogContent-root {
	padding-bottom: 25px !important;
	background: #f5f5f5;
}

@media (max-width: 599px) {
	.MuiDialogContent-root {
		padding-bottom: 0px !important;
	}
}

.btn__close {
	position: absolute !important;
	right: 20px;
	outline: none !important;
}

@media (max-width: 375px) {
	.btn__close {
		position: absolute !important;
		top: 10px;
		right: 20px;
		outline: none !important;
	}
}

.containerDialog {
	width: 650px;
	box-sizing: border-box;
}

@media (max-width: 599px) {
	.containerDialog {
		width: 100%;
		box-sizing: border-box;
	}

	.MuiPaper-root {
		width: 400px;
	}
}

.wrapperDialog {
	width: 100%;
	padding: 20px 0px 5px 0px;
}

@media (max-width: 599px) {
	.wrapperDialog {
		margin: 0px !important;
	}
}

.titleDialogProtesto {
	text-align: center;
	color: #ada282;
	margin-bottom: 30px;
}

.dateContainer,
.documentContainer {
	display: flex;
	justify-content: center;
	width: 100%;
}

.labelDialogProtesto {
	display: inline;
	margin-right: 5px;
	text-align: center;
	color: #ada282;
}

.valueDialogProtesto {
	display: inline;
	text-align: center;
}

.responseDialogProtesto {
	display: flex;
	justify-content: center;
	font-weight: bold;
	font-size: 20px;
	text-align: center;
}

@media (max-width: 599px) {
	.responseDialogProtesto {
		margin: 0px 20px;
	}
}

.messageDialogProtesto {
	text-align: center;
	margin-top: 15px;
	font-size: 12px;
	color: #ada282;
}
