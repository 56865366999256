@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Playfair+Display:400,700');

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    color: #333;
    @media (max-width:1200px) {
        font-size: 14px;
    }
    p {
        color: #666;
        line-height: 28px;
        @media (max-width:1200px) {
            line-height: 26px;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Playfair Display', serif;
        color: #282e3f;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
    }
    a {
        text-decoration: none;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -ms-transition: all 0.2s;
        transition: all 0.2s;
        &:hover{
            text-decoration: none;
        }
    }
    img {
        max-width: 100%;
    }
    
    button,
    a:focus {
        outline: none;
    }
    .btnStyle{
        a,
        button{
            background-color: #c0b596;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            padding: 10px 38px;
            border: 2px solid transparent;
            text-transform: capitalize;
            display: inline-block;
            margin-top: 3px;
            @media (max-width:1200px) {
                font-size: 14px;
                padding: 10px 35px;
            }
            @media (max-width:768px) {
                padding: 10px 13px;
            }
            &:hover{
                background: #d4c291;
            }
        }
        &.btnStyle3{
            a{
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    right: -60px;
                    top: 50%;
                    width: 85px;
                    height: 2px;
                    background: #fff;
                    bottom: 0;
                    transform: translateY(-50%);
                    transition: all .3s;
                    -webkit-transition: all .3s;
                    -moz-transition: all .3s;
                    @media (max-width:1200px) {
                        width: 60px;
                        right: -40px;
                    }
                }
                &:hover{
                    &::before{
                        right: -95px;
                    }
                }
            }
        }
    }
    .bgFFF{
        background: #fff!important;
    }
    .pt100{
        padding-top: 100px!important;
    }
}

.form {

  .formInput {

      margin-bottom: 30px;
      // input,
      // textarea,
      // select{
      //     background: rgba(192, 181, 150, .2);
      //     // height: 50px;
      //     // padding: 6px 15px;
      //     // color: #fff;
      //     // color: #bbb5a5;
      //     border-radius: 0;
      //     -webkit-box-shadow: none;
      //     box-shadow: none;
      //     // border: 1px solid rgba(192, 181, 150, .5);
      //     &:focus{
      //         -webkit-box-shadow: none;
      //         box-shadow: none;
      //         border-color: #c0b596;
      //     }
      //     &::-webkit-input-placeholder{
      //         color: #bbb5a5;
      //         font-size: 14px;
      //     }
      // }
      select {
          color: #bbb5a5;
          position: relative;
          -webkit-appearance: none;
          -ms-appearance: none;
          -o-appearance: none;
          appearance: none;
          -moz-appearance: none;          
      }
      textarea {
          height: 150px;
          padding: 15px;
      }
      p {
          text-transform: uppercase;
          font-size: 12px;
          color: #c0b596;
      }
  }
  
  button {
      width: 140px;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      padding: 9px 25px;
      border: 2px solid transparent;
      text-transform: capitalize;
      display: inline-block;
      transition: all .3s;
      background: #c0b596;
      &:hover{
          background:#d4c291;
      }
  }
  
}