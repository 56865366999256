.loader {
	height: 100vh;
	background: rgba(0, 0, 0, 0.281);
	display: flex;
	position: fixed;
	width: 100vw;
	z-index: 99999;
	top: 0;
	overflow: hidden;
}

.loading {
	margin: auto auto;
}