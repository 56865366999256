.MuiDialogTitle-root {
	background: #f5f5f5;
	width: 100%;
}

.MuiDialog-paperWidthSm {
	max-width: 650px !important;
}

.MuiTypography-h6 {
	font-family: "PlayFair Display" !important;
	font-size: 25px !important;
	font-weight: 500;
	color: #282e3f;
}

.separator {
	width: 60px;
	height: 3px;
	background: #c0b596;
	margin: -15px 0px 10px 25px;
}

.MuiDialogContent-root {
	padding-bottom: 25px !important;
	background: #f5f5f5;
}

@media (max-width: 599px) {
	.MuiDialogContent-root {
		padding: 0px !important;
	}
}

.MuiTableCell-head {
	font-size: 15px !important;
	color: #ada282 !important;
}

.tableContent {
	background: "";
	width: 650px;
	box-sizing: border-box;
}

.tableCellXs {
	display: none !important;
}

.MuiTableCell-body {
	font-family: "Open Sans", sans-serif !important;
	font-size: 15px !important;
	color: #666666 !important;
	padding: none !important;
}

.MuiInputLabel-animated {
	color: rgba(0, 0, 0, 0.54) !important;
}

@media (max-width: 599px) {
	.tableHead {
		display: none !important;
  }
  
	.tableContent {
    width: 100%;
    padding: 0px!important;
    margin: 0px!important;
	}

	.tableCell {
		display: none !important;
	}

	.tableCellXs {
		display: flex !important;
		flex-direction: column !important;
    width: 100% !important;
    margin: 0px!important;
	}

	.MuiPaper-root {
    width: 400px;
    margin: 0px 20px 0px 20px!important;
  }
  
  .MuiTableContainer-root {
    margin-left: 0px!important;
  }

	.MuiTableCell-body {
		margin: 0px!important;
  }
  
  .MuiTableCell-root {
    margin: 0px!important;
    width: 100%!important;
	}
}

@media (max-width: 375px) { 
  .MuiTypography-h6 {
    font-size: 20px !important;
  }

}
